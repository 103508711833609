import React from "react";
import styled from "styled-components";
import LegalDocument from "../LegalDocument";

const TermsContainer = styled.div``;

export default function TermsAndCondition(props) {
	return (
		<LegalDocument>
			<TermsContainer
				{...props}
				dangerouslySetInnerHTML={{
					__html: `
						<div class="iub_content legal_pp">
							<div class="iub_header">
								<h1>Terms and Conditions of <strong>ZEN Trails</strong>
								</h1>
								<p>These Terms govern</p>
								<ul>
								<li>the use of this Application, and,</li>
								<li>any other related Agreement or legal relationship with the Owner</li>
								</ul>
								<p>in a legally binding way. Capitalized words are defined in the relevant dedicated section of this document.</p>
								<br>
								<p>The User must read this document carefully.</p>
								</div>
								
								
								<p>Although the entire contractual relationship relating to these Products is entered into solely by the Owner and Users, Users acknowledge and agree that, where this Application has been provided to them via the Apple App Store, Apple may enforce these Terms as a third-party beneficiary.</p>
								<p>This Application is provided by:</p>
								
								<p>
								<strong>ZEN Trails LLC</strong> - 30 North Gould Street, Ste R Sheridan, WY 82801 (USA)</p>
								
								<p><b>Owner contact email:</b> support@zentrailsapps.com
								</p>
								
								
								<p>"This Application" refers to</p>
								<ul>
								<li>this website, including its subdomains and any other website through which the Owner makes its Service available;</li>
								</ul>
								
								<ul>
								<li>applications for mobile, tablet and other smart device systems;</li>
								</ul>
								
								<ul>
								<li>the Application Program Interfaces (API);</li>
								</ul>
								
								<ul>
								<li>the Service;</li>
								</ul>
								
								<ul>
								<li>any applications, sample and content files, source code, scripts, instruction sets or software included as part of the Service, as well as any related documentation;</li>
								</ul>
								
								
								
								<h2 id="what-the-user-should-know-at-a-glance">What the User should know at a glance</h2><ul>
								<li>Please note that some provisions in these Terms may only apply to certain categories of Users. In particular, certain provisions may only apply to Consumers or to those Users that do not qualify as Consumers. Such limitations are always explicitly mentioned within each affected clause. In the absence of any such mention, clauses apply to all Users.</li>
								</ul>
								
								<ul>
								<li>Usage of this Application and the Service is age restricted, as detailed in the relevant section of this document.</li>
								</ul>
								
								<ul>
								<li>Minors may access this Application and use its Service only under parental or adult supervision.</li>
								</ul>
								
								<hr>
								
								<h2 id="terms-of-use">TERMS OF USE</h2><p>Unless otherwise specified, the terms of use detailed in this section apply generally when using this Application.</p>
								
								<p>Single or additional conditions of use or access may apply in specific scenarios and in such cases are additionally indicated within this document.</p>
								
								<p>By using this Application, Users confirm to meet the following requirements:</p>
								<ul>
								<li>There are no restrictions for Users in terms of being Consumers or Business Users;</li>
								</ul>
								
								<ul>
								<li>Users must be older than 13;</li>
								</ul>
								
								<ul>
								<li>Minors may only use this Application under parental or adult supervision;</li>
								</ul>
								
								<ul>
								<li>Users aren’t located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist-supporting” country;</li>
								<li>Users aren’t listed on any U.S. Government list of prohibited or restricted parties;</li>
								</ul>
								
								<h3 id="account-registration">Account registration</h3>
								<p>To use the Service Users must register or create a User account, providing all required data or information in a complete and truthful manner.<br>
								Failure to do so will cause unavailability of the Service.</p>
								
								<p>Users are responsible for keeping their login credentials confidential and safe. For this reason, Users are also required to choose passwords that meet the highest standards of strength permitted by this Application.</p>
								
								<p>By registering, Users agree to be fully responsible for all activities that occur under their username and password.<br>
								Users are required to immediately and unambiguously inform the Owner via the contact details indicated in this document, if they think their personal information, including but not limited to User accounts, access credentials or personal data, have been violated, unduly disclosed or stolen.</p>
								<h4 id="conditions-for-account-registration">Conditions for account registration</h4>
								<p>Registration of User accounts on this Application is subject to the conditions outlined below. By registering, Users agree to meet such conditions.</p>
								<ul>
								<li>Accounts registered by bots or any other automated methods are not permitted.</li>
								</ul>
								
								<ul>
								<li>Unless otherwise specified, each User must register only one account.</li>
								</ul>
								
								<ul>
								<li>Unless explicitly permitted, a User account may not be shared with other persons.</li>
								</ul>
								
								<h4 id="account-termination">Account termination</h4>
								<p>Users can terminate their account and stop using the Service at any time by doing the following:</p>
								<ul>
								<li>By directly contacting the Owner at the contact details provided in this document.</li>
								</ul>
								
								<p>However, termination of the account will not be possible until the subscription period paid for by the User has expired.</p>
								<h4 id="account-suspension-and-deletion">Account suspension and deletion</h4>
								<p>The Owner reserves the right, at its sole discretion, to suspend or delete at any time and without notice, User accounts which it deems inappropriate, offensive or in violation of these Terms.</p>
								
								<p>The suspension or deletion of User accounts shall not entitle Users to any claims for compensation, damages or reimbursement.</p>
								
								<p>The suspension or deletion of accounts due to causes attributable to the User does not exempt the User from paying any applicable fees or prices.</p>
								<h3 id="content-on-this_application">Content on this Application</h3>
								<p>Unless where otherwise specified or clearly recognizable, all content available on this Application is owned or provided by the Owner or its licensors.</p>
								
								<p>The Owner undertakes its utmost effort to ensure that the content provided on this Application infringes no applicable legal provisions or third-party rights. However, it may not always be possible to achieve such a result.<br>
								In such cases, without prejudice to any legal prerogatives of Users to enforce their rights, Users are kindly asked to preferably report related complaints using the contact details provided in this document.</p>
								<h4 id="rights-regarding-content-on-this_application-all-rights-reserved">Rights regarding content on this Application - All rights reserved</h4>
								<p>The Owner holds and reserves all intellectual property rights for any such content.</p>
								
								<p>Users may not therefore use such content in any way that is not necessary or implicit in the proper use of the Service.</p>
								<p>In particular, but without limitation, Users may not copy, download, share (beyond the limits set forth below), modify, translate, transform, publish, transmit, sell, sublicense, edit, transfer/assign to third parties or create derivative works from the content available on this Application, nor allow any third party to do so through the User or their device, even without the User's knowledge.</p>
								
								<p>Where explicitly stated on this Application, the User may download, copy and/or share some content available through this Application for its sole personal and non-commercial use and provided that the copyright attributions and all the other attributions requested by the Owner are correctly implemented.</p>
								
								<p>Any applicable statutory limitation or exception to copyright shall stay unaffected.</p>
								<h3 id="content-provided-by-users">Content provided by Users</h3>
								<p>The Owner allows Users to upload, share or provide their own content to this Application.</p>
								
								<p>By providing content to this Application, Users confirm that they are legally allowed to do so and that they are not infringing any statutory provisions and/or third-party rights.</p>
								<p>Users acknowledge and accept that by providing their own content to this Application they grant the Owner a non-exclusive, worldwide, fully paid-up and royalty-free, irrevocable, perpetual (or for the entire protection term), sub-licensable and transferable license to use, access, store, reproduce, modify, distribute, publish, process into derivative works, broadcast, stream, transmit or otherwise exploit such content to provide and promote its Service in any media or manner.</p>
								
								<p>To the extent permitted by applicable law, Users waive any moral rights in connection with content they provide to this Application.</p>
								<p>Users acknowledge, accept and confirm that all content they provide through this Application is provided subject to the same general conditions set forth for content on this Application.</p>
								<h4 id="liability-for-provided-content">Liability for provided content</h4>
								<p>Users are solely liable for any content they upload, post, share, or provide through this Application. Users acknowledge and accept that <strong>the Owner does not filter or moderate such content</strong>.</p>
								
								<p>However, the Owner reserves the right to remove, delete or block such content at its own discretion and, without prior notice, to deny the uploading User access to this Application:</p>
								
								<ul>
								<li>upon becoming aware of any (alleged) violation of these Terms, any third-party rights, or applicable law, based on such content;</li>
								<li>if a notice of infringement of intellectual property rights is received;</li>
								<li>if a notice of violation of a third party’s privacy, including their intimate privacy, is received;</li>
								<li>upon order of a public authority; or</li>
								<li>where the Owner is made aware that the content, while being accessible via this Application, may represent a risk for Users, third parties and/or the availability of the Service.</li>
								</ul>
								
								
								<p>The removal, deletion or blocking of content shall not entitle Users that have provided such content or that are liable for it, to any claims for compensation, damages or reimbursement.</p>
								
								<p>Users agree to hold the Owner harmless from and against any claim asserted and/or damage suffered due to content they provided to or provided through this Application.</p>
								<h4 id="removal-of-content-from-parts-of-this_application-available-through-the-app-store">Removal of content from parts of this Application available through the App Store</h4>
								<p>If the reported content is deemed objectionable, it will be removed within 24 hours and the User who provided the content will be barred from using the Service.</p>
								<h3 id="access-to-external-resources">Access to external resources</h3>
								<p>Through this Application Users may have access to external resources provided by third parties. Users acknowledge and accept that the Owner has no control over such resources and is therefore not responsible for their content and availability.</p>
								
								<p>Conditions applicable to any resources provided by third parties, including those applicable to any possible grant of rights in content, result from each such third parties’ terms and conditions or, in the absence of those, applicable statutory law.</p>
								<h3 id="acceptable-use">Acceptable use</h3>
								<p>This Application and the Service may only be used within the scope of what they are provided for, under these Terms and applicable law.</p>
								
								<p>Users are solely responsible for making sure that their use of this Application and/or the Service violates no applicable law, regulations or third-party rights.</p>
								<p>Therefore, <strong>the Owner reserves the right to take any appropriate measure to protect its legitimate interests including by denying Users access to this Application or the Service, terminating contracts, reporting any misconduct performed through this Application or the Service to the competent authorities – such as judicial or administrative authorities - whenever Users engage or are suspected to engage in any of the following activities:</strong></p>
								
								<ul>
								<li>violate laws, regulations and/or these Terms;</li>
								<li>infringe any third-party rights;</li>
								<li>considerably impair the Owner’s legitimate interests;</li>
								<li>offend the Owner or any third party.</li>
								</ul>
								
								<h3 id="api-usage-terms">API usage terms</h3>
								<p>Users may access their data relating to this Application via the Application Program Interface (API). Any use of the API, including use of the API through a third-party product/service that accesses this Application, is bound by these Terms and, in addition, by the following specific terms:</p>
								
								<ul>
								<li>the User expressly understands and agrees that the Owner bears no responsibility and shall not be held liable for any damages or losses resulting from the User’s use of the API or their use of any third-party products/services that access data through the API.</li>
								</ul>
								
								
								<h2 id="terms-and-conditions-of-sale">TERMS AND CONDITIONS OF SALE</h2><h3 id="paid-products">Paid Products</h3>
								<p>Some of the Products provided on this Application, as part of the Service, are provided on the basis of payment.</p>
								
								<p>The fees, duration and conditions applicable to the purchase of such Products are described below and in the dedicated sections of this Application.</p>
								<p>To purchase Products, the User must register or log into this Application.</p>
								<h3 id="product-description">Product description</h3>
								<p>Prices, descriptions or availability of Products are outlined in the respective sections of this Application and are subject to change without notice.</p>
								
								<p>While Products on this Application are presented with the greatest accuracy technically possible, representation on this Application through any means (including, as the case may be, graphic material, images, colors, sounds) is for reference only and implies no warranty as to the characteristics of the purchased Product.</p>
								
								<p>The characteristics of the chosen Product will be outlined during the purchasing process.</p>
								<h3 id="purchasing-process">Purchasing process</h3>
								<p>Any steps taken from choosing a Product to order submission form part of the purchasing process.</p>
								
								<p>The purchasing process includes these steps:</p>
								
								<ul>
								<li>Users must choose the desired Product and verify their purchase selection.</li>
								<li>After having reviewed the information displayed in the purchase selection, Users may place the order by submitting it.</li>
								</ul>
								
								<h3 id="order-submission">Order submission</h3>
								<p>When the User submits an order, the following applies:</p>
								
								<ul>
								<li>The submission of an order determines contract conclusion and therefore creates for the User the obligation to pay the price, taxes and possible further fees and expenses, as specified on the order page.</li>
								<li>In case the purchased Product requires an action from the User, such as the provision of personal information or data, specifications or special wishes, the order submission creates an obligation for the User to cooperate accordingly.</li>
								<li>Upon submission of the order, Users will receive a receipt confirming that the order has been received.</li>
								</ul>
								
								<p>All notifications related to the described purchasing process shall be sent to the email address provided by the User for such purposes.</p>
								<h3 id="prices">Prices</h3>
								<p>Users are informed during the purchasing process and before order submission, about any fees, taxes and costs (including, if any, delivery costs) that they will be charged.</p>
								
								<p>Prices on this Application are displayed:</p>
								<ul>
								<li>either exclusive or inclusive of any applicable fees, taxes and costs, depending on the section the User is browsing.</li>
								</ul>
								
								<h3 id="methods-of-payment">Methods of payment</h3>
								<p>Information related to accepted payment methods are made available during the purchasing process.</p>
								
								<p>Some payment methods may only be available subject to additional conditions or fees. In such cases related information can be found in the dedicated section of this Application.</p>
								<p>All payments are independently processed through third-party services. Therefore, this Application does not collect any payment information – such as credit card details – but only receives a notification once the payment has been successfully completed.</p>
								
								<p>If a payment through the available methods fails or is refused by the payment service provider, the Owner shall be under no obligation to fulfill the purchase order. If a payment fails or is refused, the Owner reserves the right to claim any related expenses or damages from the User.</p>
								<h3 id="purchase-via-app-store">Purchase via app store</h3>
								<p>This Application or specific Products available for sale on this Application must be purchased via a third-party app store. To access such purchases, Users must follow the instructions provided on the relevant online store (such as "Apple App Store" or "Google Play"), which may vary depending on the particular device in use.</p>
								
								<p>Unless otherwise specified, purchases done via third-party online stores are also subject to such third-parties’ terms and conditions, which, in case of any inconsistency or conflict, shall always prevail upon these Terms.</p>
								
								<p>Users purchasing through such third-party online stores must therefore read such terms and conditions of sale carefully and accept them.</p>
								<h3 id="retention-of-usage-rights">Retention of usage rights</h3>
								<p>Users do not acquire any rights to use the purchased Product until the total purchase price is received by the Owner.</p>
								<h3 id="delivery">Delivery</h3><h4 id="performance-of-services">Performance of services</h4>
								<p>The purchased service shall be performed or made available within the timeframe specified on this Application or as communicated before the order submission.</p>
								<h3 id="contract-duration">Contract duration</h3><h4 id="subscriptions">Subscriptions</h4>
								<p>Subscriptions allow Users to receive a Product continuously or regularly over time. Details regarding the type of subscription and termination are outlined below.</p>
								<h4 id="open-ended-subscriptions">Open-ended subscriptions</h4>
								<p>Paid subscriptions begin on the day the payment is received by the Owner.</p>
								
								<p>In order to maintain subscriptions, Users must pay the required recurring fee in a timely manner. Failure to do so may cause service interruptions.</p>
								<h4 id="subscriptions-handled-via-apple-id">Subscriptions handled via Apple ID</h4>
								<p>Users may subscribe to a Product using the Apple ID associated with their Apple App Store account by using the relevant process on this Application. When doing so, Users acknowledge and accept that</p>
								
								<ul>
								<li>any payment due shall be charged to their Apple ID account;</li>
								<li>subscriptions are automatically renewed for the same duration unless the User cancels at least 24 hours before the current period expires;</li>
								<li>any and all fees or payments due for renewal will be charged within 24-hours before the end of the current period;</li>
								<li>subscriptions can be managed or cancelled in the Users’ Apple App Store account settings.</li>
								</ul>
								
								
								<p>The above shall prevail upon any conflicting or diverging provision of these Terms.</p>
								<h5 id="termination">Termination</h5>
								<p><strong>Subscriptions may be terminated by sending a clear and unambiguous termination notice to the Owner using the contact details provided in this document, or — if applicable — by using the corresponding controls inside this Application.</strong></p>
								<h4 id="termination-of-open-ended-subscriptions">Termination of open-ended subscriptions</h4>
								<p><strong>Open-ended subscriptions may be terminated at any time by sending a clear and unambiguous termination notice to the Owner using the contact details provided in this document, or — if applicable — by using the corresponding controls inside this Application.</strong></p>
								
								<p><strong>Terminations shall take effect 0 days after the notice of termination has been received by the Owner.</strong></p>
								
								
								
								<h2 id="liability-and-indemnification">Liability and indemnification</h2><h3 id="australian-users">Australian Users</h3><h4 id="limitation-of-liability">Limitation of liability</h4>
								<p>Nothing in these Terms excludes, restricts or modifies any guarantee, condition, warranty, right or remedy which the User may have under the Competition and Consumer Act 2010 (Cth) or any similar State and Territory legislation and which cannot be excluded, restricted or modified (non-excludable right). To the fullest extent permitted by law, our liability to the User, including liability for a breach of a non-excludable right and liability which is not otherwise excluded under these Terms of Use, is limited, at the Owner’s sole discretion, to the re-performance of the services or the payment of the cost of having the services supplied again.</p>
								<h3 id="us-users">US Users</h3><h4 id="disclaimer-of-warranties">Disclaimer of Warranties</h4>
								<p><strong>This Application is provided strictly on an “as is” and “as available” basis.  Use of the Service is at Users’ own risk. To the maximum extent permitted by applicable law, the Owner expressly disclaims all conditions, representations, and warranties — whether express, implied, statutory or otherwise, including, but not limited to, any implied warranty of merchantability, fitness for a particular purpose, or non-infringement of third-party rights. No advice or information, whether oral or written, obtained by user from owner or through the Service will create any warranty not expressly stated herein.</strong></p>
								
								<p><strong>Without limiting the foregoing, the Owner, its subsidiaries, affiliates, licensors, officers, directors, agents, co-branders, partners, suppliers and employees do not warrant that the content is accurate, reliable or correct; that the Service will meet Users’ requirements; that the Service will be available at any particular time or location, uninterrupted or secure; that any defects or errors will be corrected; or that the Service is free of viruses or other harmful components. Any content downloaded or otherwise obtained through the use of the Service is downloaded at users own risk and users shall be solely responsible for any damage to Users’ computer system or mobile device or loss of data that results from such download or Users’ use of the Service.</strong></p>
								
								<p><strong>The Owner does not warrant, endorse, guarantee, or assume responsibility for any product or service advertised or offered by a third party through the Service or any hyperlinked website or service, and the Owner shall not be a party to or in any way monitor any transaction between Users and third-party providers of products or services.</strong></p>
								
								<p><strong>The Service may become inaccessible or it may not function properly with Users’ web browser, mobile device, and/or operating system. The owner cannot be held liable for any perceived or actual damages arising from Service content, operation, or use of this Service.</strong></p>
								
								<p><strong>Federal law, some states, and other jurisdictions, do not allow the exclusion and limitations of certain implied warranties. The above exclusions may not apply to Users. This Agreement gives Users specific legal rights, and Users may also have other rights which vary from state to state. The disclaimers and exclusions under this agreement shall not apply to the extent prohibited by applicable law.</strong></p>
								<h4 id="limitations-of-liability">Limitations of liability</h4>
								<p><strong>To the maximum extent permitted by applicable law, in no event shall the Owner, and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners, suppliers and employees be liable for</strong></p>
								
								<ul>
								<li><strong>any indirect, punitive, incidental, special, consequential or exemplary damages, including without limitation damages for loss of profits, goodwill, use, data or other intangible losses, arising out of or relating to the use of, or inability to use, the Service; and</strong></li>
								<li><strong>any damage, loss or injury resulting from hacking, tampering or other unauthorized access or use of the Service or User account or the information contained therein;</strong></li>
								<li><strong>any errors, mistakes, or inaccuracies of content;</strong></li>
								<li><strong>personal injury or property damage, of any nature whatsoever, resulting from User access to or use of the Service;</strong></li>
								<li><strong>any unauthorized access to or use of the Owner’s secure servers and/or any and all personal information stored therein;</strong></li>
								<li><strong>any interruption or cessation of transmission to or from the Service;</strong></li>
								<li><strong>any bugs, viruses, trojan horses, or the like that may be transmitted to or through the Service;</strong></li>
								<li><strong>any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the Service; and/or</strong></li>
								<li><strong>the defamatory, offensive, or illegal conduct of any User or third party.  In no event shall the Owner, and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners, suppliers and employees be liable for any claims, proceedings, liabilities, obligations, damages, losses or costs in an amount exceeding the amount paid by User to the Owner hereunder in the preceding 12 months, or the period of duration of this agreement between the Owner and User, whichever is shorter.</strong></li>
								</ul>
								
								
								<p><strong>This limitation of liability section shall apply to the fullest extent permitted by law in the applicable jurisdiction whether the alleged liability is based on contract, tort, negligence, strict liability, or any other basis, even if company has been advised of the possibility of such damage.</strong></p>
								
								<p><strong>Some jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, therefore the above limitations or exclusions may not apply to User.  The terms give User specific legal rights, and User may also have other rights which vary from jurisdiction to jurisdiction.  The disclaimers, exclusions, and limitations of liability under the terms shall not apply to the extent prohibited by applicable law.</strong></p>
								<h4 id="indemnification">Indemnification</h4>
								<p><strong>The User agrees to defend, indemnify and hold the Owner and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners, suppliers and employees harmless from and against any and all claims or demands, damages, obligations, losses, liabilities, costs or debt, and expenses, including, but not limited to, legal fees and expenses, arising from</strong></p>
								
								<ul>
								<li><strong>User’s use of and access to the Service, including any data or content transmitted or received by User;</strong></li>
								<li><strong>User’s violation of these terms, including, but not limited to, User’s breach of any of the representations and warranties set forth in these terms;</strong></li>
								<li><strong>User’s violation of any third-party rights, including, but not limited to, any right of privacy or intellectual property rights;</strong></li>
								<li><strong>User’s violation of any statutory law, rule, or regulation;</strong></li>
								<li><strong>any content that is submitted from User’s account, including third party access with User’s unique username, password or other security measure, if applicable, including, but not limited to, misleading, false, or inaccurate information;</strong></li>
								<li><strong>User’s wilful misconduct; or</strong></li>
								<li><strong>statutory provision by User or its affiliates, officers, directors, agents, co-branders, partners, suppliers and employees to the extent allowed by applicable law.</strong></li>
								</ul>
								
								
								<h2 id="common-provisions">Common provisions</h2><h3 id="no-waiver">No Waiver</h3>
								<p>The Owner’s failure to assert any right or provision under these Terms shall not constitute a waiver of any such right or provision.  No waiver shall be considered a further or continuing waiver of such term or any other term.</p>
								<h3 id="service-interruption">Service interruption</h3>
								<p>To ensure the best possible service level, the Owner reserves the right to interrupt the Service for maintenance, system updates or any other changes, informing the Users appropriately.</p>
								
								<p>Within the limits of law, the Owner may also decide to suspend or discontinue the Service altogether. If the Service is discontinued, the Owner will cooperate with Users to enable them to withdraw Personal Data or information and will respect Users' rights relating to continued product use and/or compensation, as provided for by applicable law.</p>
								
								<p>Additionally, the Service might not be available due to reasons outside the Owner’s reasonable control, such as “force majeure” events( infrastructural breakdowns or blackouts etc.).</p>
								<h3 id="service-reselling">Service reselling</h3>
								<p>Users may not reproduce, duplicate, copy, sell, resell or exploit any portion of this Application and of its Service without the Owner’s express prior written permission, granted either directly or through a legitimate reselling programme.</p>
								<h3 id="privacy-policy">Privacy policy</h3>
								<p>To learn more about the use of their Personal Data, Users may refer to the privacy policy of this Application.</p>
								<h3 id="intellectual-property-rights">Intellectual property rights</h3>
								<p>Without prejudice to any more specific provision of these Terms, any intellectual property rights, such as copyrights, trademark rights, patent rights and design rights related to this Application are the exclusive property of the Owner or its licensors and are subject to the protection granted by applicable laws or international treaties relating to intellectual property.</p>
								
								<p>All trademarks — nominal or figurative — and all other marks, trade names, service marks, word marks, illustrations, images, or logos appearing in connection with this Application are, and remain, the exclusive property of the Owner or its licensors and are subject to the protection granted by applicable laws or international treaties related to intellectual property.</p>
								<h3 id="changes-to-these-terms">Changes to these Terms</h3>
								<p>The Owner reserves the right to amend or otherwise modify these Terms at any time. In such cases, the Owner will appropriately inform the User of these changes.</p>
								
								<p>Such changes will only affect the relationship with the User from the date communicated to Users onwards.</p>
								
								<p>The continued use of the Service will signify the User’s acceptance of the revised Terms. If Users do not wish to be bound by the changes, they must stop using the Service and may terminate the Agreement.</p>
								
								<p>The applicable previous version will govern the relationship prior to the User's acceptance. The User can obtain any previous version from the Owner.</p>
								<p>If legally required, the Owner will notify Users in advance of when the modified Terms will take effect.</p>
								<h3 id="assignment-of-contract">Assignment of contract</h3>
								<p>The Owner reserves the right to transfer, assign, dispose of by novation, or subcontract any or all rights or obligations under these Terms, taking the User’s legitimate interests into account.
								Provisions regarding changes of these Terms will apply accordingly.</p>
								
								<p>Users may not assign or transfer their rights or obligations under these Terms in any way, without the written permission of the Owner.</p>
								<h3 id="contacts">Contacts</h3>
								<p>All communications relating to the use of this Application must be sent using the contact information stated in this document.</p>
								<h3 id="severability">Severability</h3>
								<p>Should any provision of these Terms be deemed or become invalid or unenforceable under applicable law, the invalidity or unenforceability of such provision shall not affect the validity of the remaining provisions, which shall remain in full force and effect.</p>
								<h4 id="us-users">US Users</h4>
								<p>Any such invalid or unenforceable provision will be interpreted, construed and reformed to the extent reasonably required to render it valid, enforceable and consistent with its original intent.
								These Terms constitute the entire Agreement between Users and the Owner with respect to the subject matter hereof, and supersede all other communications, including but not limited to all prior agreements, between the parties with respect to such subject matter.
								These Terms will be enforced to the fullest extent permitted by law.</p>
								<h4 id="eu-users">EU Users</h4>
								<p>Should any provision of these Terms be or be deemed void, invalid or unenforceable, the parties shall do their best to find, in an amicable way, an agreement on valid and enforceable provisions thereby substituting the void, invalid or unenforceable parts.<br>
								In case of failure to do so, the void, invalid or unenforceable provisions shall be replaced by the applicable statutory provisions, if so permitted or stated under the applicable law.</p>
								
								<p>Without prejudice to the above, the nullity, invalidity or the impossibility to enforce a particular provision of these Terms shall not nullify the entire Agreement, unless the severed provisions are essential to the Agreement, or of such importance that the parties would not have entered into the contract if they had known that the provision would not be valid, or in cases where the remaining provisions would translate into an unacceptable hardship on any of the parties.</p>
								<h3 id="governing-law">Governing law</h3>
								<p>These Terms are governed by the law of the place where the Owner is based, as disclosed in the relevant section of this document, without regard to conflict of laws principles.</p>
								<h4 id="prevalence-of-national-law">Prevalence of national law</h4>
								<p>However, regardless of the above, if the law of the country that the User is located in provides for a higher applicable consumer protection standard, such higher standards shall prevail.</p>
								<h3 id="venue-of-jurisdiction">Venue of jurisdiction</h3>
								<p>The exclusive competence to decide on any controversy resulting from or connected to these Terms lies with the courts of the place where the Owner is based, as displayed in the relevant section of this document.</p>
								<h4 id="exception-for-consumers-in-europe">Exception for Consumers in Europe</h4>
								<p>The above does not apply to any Users that qualify as European Consumers, nor to Consumers based in the United Kingdom, Switzerland, Norway or Iceland.</p>
								
								<h2 id="dispute-resolution">Dispute resolution</h2><h3 id="amicable-dispute-resolution">Amicable dispute resolution </h3>
								<p>Users may bring any disputes to the Owner who will try to resolve them amicably.</p>
								
								<p>While Users' right to take legal action shall always remain unaffected, in the event of any controversy regarding the use of this Application or the Service, Users are kindly asked to contact the Owner at the contact details provided in this document.</p>
								<p>The User may submit the complaint including a brief description and if applicable, the details of the related order, purchase, or account, to the Owner’s email address specified in this document.</p>
								
								<p>The Owner will process the complaint without undue delay and within 21 days of receiving it.</p>
								<h3 id="online-dispute-resolution-for-consumers">Online dispute resolution for Consumers</h3>
								<p>The European Commission has established an online platform for alternative dispute resolutions that facilitates an out-of-court method for solving disputes related to and stemming from online sale and service contracts.</p>
								
								<p>As a result, any European Consumer or Consumer based in Norway, Iceland, or Liechtenstein can use such platform for resolving disputes stemming from contracts which have been entered into online. The platform is <a href="http://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener">available at the following link</a>.</p>
								
								<div class="one_line_col">
									<div class="box_primary box_10 definitions expand collapsed">
									<h2 class="expand-click w_icon_24 icon_ribbon">
										Definitions and legal references
									</h2>
									<div class="expand-content" style="display: none;">
								<h4>This Application (or this Application)</h4>
								<p>The property that enables the provision of the Service.</p>
								<h4>Agreement</h4>
								<p>Any legally binding or contractual relationship between the Owner and the User, governed by these Terms.</p>
								<h4>Business User</h4>
								<p>Any User that does not qualify as a Consumer.</p>
								<h4>European (or Europe)</h4>
								<p>Applies where a User, regardless of nationality, is in the EU.</p>
								<h4>Owner (or We)</h4>
								<p>Indicates the natural person(s) or legal entity that provides this Application and/or the Service to Users.</p>
								<h4>Product</h4>
								<p>A good or service available for purchase through this Application, such as e.g. physical goods, digital files, software, booking services etc.</p>
								
								<p>The sale of Products may be part of the Service.</p>
								<h4>Service</h4>
								<p>The service provided by this Application as described in these Terms and on this Application.</p>
								<h4>Terms</h4>
								<p>All provisions applicable to the use of this Application and/or the Service as described in this document, including any other related documents or agreements, and as updated from time to time.</p>
								<h4>User (or You)</h4>
								<p>Indicates any natural person or legal entity using this Application.</p>
								<h4>Consumer</h4>
								<p>Consumer is any User qualifying as such under applicable law.</p>
								</div>
									</div>
								</div>
											<div class="iub_footer">
								
									<p>
									Latest update: June 16, 2023
									</p>
								
								</div> <!-- /footer -->
							</div>
				`,
				}}
			/>
		</LegalDocument>
	);
}
