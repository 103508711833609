import React from "react";
import styled from "styled-components";

import { SpinningRays, LogoCircle } from "zt-web";

import PaddleImage from "../../images/paddle.svg";
import AppleAppStoreDownloadImage from "./images/Download_on_the_Apple_App_Store.svg";

const Header = styled.header`
	height: 300px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
	max-width: 700px;
	padding: 0 50px;
	position: relative;
	width: 100%;
`;

const Main = styled.main`
	background-color: #45a545;
	box-sizing: border-box;
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	padding: 40px 0 40px 0;
	position: relative;
	width: 100%;
`;

const MainHeading = styled.h1`
	color: #000;
	font-family: aw-conqueror-inline, sans-serif;
	font-size: 70px;
	font-style: normal;
	font-weight: 400;
	line-height: 1;
	margin: 0 100px 20px;
	max-width: 700px;
`;

const SubHeading = styled.h2`
	color: #fff;
	font-size: 25px;
	margin: 0 20px 20px;
	max-width: 700px;

	@media (min-width: 700px) {
		font-size: 40px;
		margin-left: 100px;
		margin-right: 100px;
	}
`;

const AppStoreContainer = styled.div`
	color: #fff;
	font-size: 25px;
	margin: 20px;
	max-width: 700px;

	@media (min-width: 700px) {
		font-size: 40px;
		margin-left: 100px;
		margin-right: 100px;
	}
`;

const OrContainer = styled.div`
	color: #fff;
	font-size: 22px;
`;

export default function Home() {
	return (
		<>
			<Header>
				<SpinningRays />
				<LogoCircle>
					<img src={PaddleImage} alt="" style={{ height: "100px" }} />
				</LogoCircle>
			</Header>

			<Main>
				<MainHeading>ZEN Kayak</MainHeading>
				<SubHeading>The mobile app for those who love to kayak.</SubHeading>
				<AppStoreContainer>
					<a href="https://apps.apple.com/us/app/zen-kayak/id1496639813">
						<img
							src={AppleAppStoreDownloadImage}
							alt="Download on the Apple App Store"
							style={{ height: "40px" }}
						/>
					</a>
				</AppStoreContainer>
				<OrContainer>or</OrContainer>
				<AppStoreContainer>
					<a href="https://play.google.com/store/apps/details?id=com.zentrailsapps.zenkayak&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
						<img
							alt="Get it on Google Play"
							src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
							style={{ height: "55px" }}
						/>
					</a>
				</AppStoreContainer>
			</Main>
		</>
	);
}
