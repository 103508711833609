import React from "react";
import styled from "styled-components";

const FooterContainer = styled.footer`
	background-color: #45a545;
	color: #fff;
	font-size: 12px;
	padding: 10px 5px;
	text-align: center;
	width: 100%;
`;

const FooterList = styled.ul`
	display: flex;
	flex-direction: row;
	justify-content: center;
	list-style-type: none;
	margin: 0;
	padding: 0;
`;

const FooterItem = styled.li`
	padding: 10px;
`;

const FooterLink = styled.a`
	color: #fff;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
`;

export default function Footer(props) {
	return (
		<FooterContainer {...props}>
			<FooterList>
				<FooterItem>
					<FooterLink href="/terms-and-conditions">Terms</FooterLink>
				</FooterItem>
				<FooterItem>
					<FooterLink href="/privacy-policy">Privacy Policy</FooterLink>
				</FooterItem>
				<FooterItem>
					<FooterLink href="contact-us">Contact Us</FooterLink>
				</FooterItem>

				<FooterItem>
					<small>© 2023 ZEN Trails LLC</small>
				</FooterItem>
			</FooterList>
		</FooterContainer>
	);
}
