import React from "react";
import yellow_rays from "./images/yellow_rays.svg";
import green_rays from "./images/green_rays.svg";
import "./index.css";

function SpinningRays() {
	return (
		<div className="SpinningRays">
			<img src={yellow_rays} className="SpinningRays-yellow-rays" alt="" />
			<img src={green_rays} className="SpinningRays-green-rays" alt="" />
		</div>
	);
}

export default SpinningRays;
