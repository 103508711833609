import React from "react";
import styled from "styled-components";

const Circle = styled.h1`
	align-items: center;
	background-color: #87a842;
	border: 5px solid #000;
	border-radius: 50%;
	color: #000;
	display: flex;
	flex-direction: column;
	font-family: aw-conqueror-inline, sans-serif;
	font-style: normal;
	font-weight: 400;
	height: 200px;
	justify-content: center;
	line-height: 1;
	margin: 0;
	position: absolute;
	width: 200px;
`;

export default function LogoCircle({ children, style }) {
	return <Circle style={style}>{children}</Circle>;
}
