import React from "react";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";

const Form = styled.form`
	background-color: #fff;
	border-radius: 10px;
	font-size: 12px;
	margin: 30px;
	max-width: 450px;
	padding: 20px;
	text-align: center;
	width: 100%;
`;

const Heading = styled.h1`
	font-size: 30px;
	margin: 0;
	padding: 0;
`;

const SubHeading = styled.h2`
	font-size: 15px;
	margin: 0;
	padding: 0;
`;

const Success = styled.div`
	font-size: 20px;
`;

const ErrorContainer = styled.div`
	color: #f00;
	font-size: 15px;
	margin-top: 5px;
`;

const TextInput = styled(TextField)`
	width: 100%;

	&:disabled {
		backgroundcolor: #ddd;
		cursor: not-allowed;
	}
`;

const Textarea = styled(TextField)`
	width: 100%;

	&:disabled {
		backgroundcolor: #ddd;
		cursor: not-allowed;
	}
`;

const ButtonContainer = styled.div`
	text-align: right;
`;

export default function ContactUsForm({
	onSubmit,
	success,
	error,
	disabled,
	...rest
}) {
	return (
		<Form onSubmit={onSubmit}>
			{success && <Success>Thank you! Your message has been sent.</Success>}
			{!success && (
				<div>
					<Heading>Send us a message</Heading>
					<SubHeading>
						Give us your feedback, questions, complaints, compliments, and
						theories on the meaning of life.
					</SubHeading>
					{error && <ErrorContainer>error</ErrorContainer>}
					<TextInput
						name="name"
						label="Name"
						margin="normal"
						variant="outlined"
						disabled={disabled}
					/>
					<TextInput
						name="email"
						label="Email"
						margin="normal"
						variant="outlined"
						disabled={disabled}
					/>
					<Textarea
						name="message"
						label="Your thoughts"
						multiline
						rows="8"
						margin="normal"
						variant="outlined"
						disabled={disabled}
					/>
					<ButtonContainer>
						<Button
							variant="contained"
							style={{ backgroundColor: "rgb(162, 200, 60)" }}
							endIcon={<SendIcon />}
							type="submit"
							disabled={disabled}
						>
							{disabled ? "Sending..." : "Send"}
						</Button>
					</ButtonContainer>
				</div>
			)}
		</Form>
	);
}
