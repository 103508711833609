import React from "react";
import styled from "styled-components";

const Container = styled.article`
	background-color: #fff;
	color: #000;
	font-size: 12px;
	margin: 24px;
	padding: 10px 32px;
	text-align: left;
	max-width: 1080px;
`;

export default function LegalDocument({ children, ...rest }) {
	return <Container {...rest}>{children}</Container>;
}
