import React from "react";
import styled from "styled-components";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
	ApolloClient,
	InMemoryCache,
	ApolloProvider,
	HttpLink,
} from "@apollo/client";

import { Footer, PrivacyPolicy, TermsAndConditions } from "zt-web";

import Home from "./scenes/Home";
import ContactUs from "./scenes/ContactUs";
import "./App.css";

const TopHalf = styled.section`
	align-items: center;
	display: flex;
	flex: 1;
	flex-direction: column;
	width: 100%;
`;

const link = new HttpLink({
	uri: "https://us-central1-zentrails-dev.cloudfunctions.net/api/graphql",
});
const client = new ApolloClient({
	link,
	cache: new InMemoryCache(),
});

function App() {
	return (
		<ApolloProvider client={client}>
			<Router>
				<div className="App">
					<TopHalf>
						<Switch>
							<Route path="/" exact={true} component={Home} />
							<Route path="/contact-us" exact={true} component={ContactUs} />
							<Route
								path="/privacy-policy"
								exact={true}
								component={PrivacyPolicy}
							/>
							<Route
								path="/terms-and-conditions"
								exact={true}
								component={TermsAndConditions}
							/>
						</Switch>
					</TopHalf>

					<Footer />
				</div>
			</Router>
		</ApolloProvider>
	);
}

export default App;
